import { useEffect } from "react";

import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
import Aos from "aos";
import "aos/dist/aos.css";
import FadeBlurScaleOnEnter from "../../components/AnimationProps/FadeBlurScaleOnEnter";
import { ContactImg0 } from "../../headlineComponents/indexImg";
import "./style-contact.scss";
import { HeadlinePatterOpt2 } from "../../headlineComponents";

const Contact = () => {
  const { ref, inView } = useInView();
  const animationOnviewHookH1 = useAnimation();

  useEffect(() => {
    if (inView) {
      animationOnviewHookH1.start({
        y: 0,
        transition: {
          type: "spring",
          duration: 1,
          damping: 14,
          delay: 0.8,
        },
      });
    }

    if (!inView) {
      animationOnviewHookH1.start({
        y: 650,
      });
    }
  }, [inView, animationOnviewHookH1]);

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <section ref={ref} id="wrapper__contact-Screen">
      <HeadlinePatterOpt2
        titlePatt="Hello."
        subTitlePatt1="How can we"
        subTitlePatt2="help you today?"
      />

      <div className="container___contact-Screen">
        <div className="wrapper-description__contact-screen">
          <div className="container-description__contact-screen">
            <div className="desc_contact-screen_left">
              <div
                className="container-imgBox-contact"
                data-aos="fade-down-right"
                data-aos-easing="linear"
                data-aos-duration="1800"
              >
                <FadeBlurScaleOnEnter>
                  <motion.img
                    src={ContactImg0}
                    drag
                    whileDrag={{ scale: 1.4 }}
                    dragConstraints={{
                      top: -20,
                      right: 20,
                      bottom: 20,
                      left: -20,
                    }}
                    alt="photograph of the artist"
                  />
                </FadeBlurScaleOnEnter>
              </div>
            </div>

            <div className="desc_contact-screen_right">
              <div className="container-text-email">
                <h1
                  data-aos="fade-down"
                  data-aos-easing="linear"
                  data-aos-duration="800"
                >
                  EMAIL US
                </h1>
                <h3>
                  CONTACT.MEYOKO<span>[at]GMAIL.COM</span>
                </h3>
              </div>

              <div className="container-text-call">
                <h1
                  data-aos="fade-down"
                  data-aos-easing="linear"
                  data-aos-duration="1400"
                >
                  CALL US
                </h1>
                <h3>
                  0033+ <span> | 9771 95 535</span>
                </h3>{" "}
              </div>
              <div className="container-text-call">
                <h1
                  data-aos="fade-down"
                  data-aos-easing="linear"
                  data-aos-duration="1800"
                >
                  THE STORE
                </h1>

                <a
                  style={{ color: "#aaaaaa" }}
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://meyoko-creative-store.company.site/"
                >
                  meyoko-creative-store
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
