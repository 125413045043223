import { useParams } from "react-router-dom";
// import DragZoomImg from "../AnimationProps/DragZoomImg";
import collectionsMeyoko from "../../data_CollectionsData.js";

import { motion } from "framer-motion";

import "./styles-largImg-collection.scss";

const Index = ({ img }) => {
  //
  //
  const { id } = useParams();
  const drawingItemArch = collectionsMeyoko.find((p) => p._id === id);

  return (
    <>
      <div className="largeImg-wrapper-collection">
        <motion.div className="imgBox-drawingScreen__archive">
          <motion.img
            drag
            whileDrag={{ scale: 1.2 }}
            dragConstraints={{
              top: -5,
              right: 5,
              bottom: 5,
              left: -5,
            }}
            className="img-lg-drawingScreenArch"
            src={img}
            alt={drawingItemArch.nameDrawingAltCollection}
          />

          <div className="zoom-google-icon"></div>
        </motion.div>
      </div>
    </>
  );
};

export default Index;
