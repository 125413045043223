import { NavLink, useLocation } from "react-router-dom";
import { PiInstagramLogo } from "react-icons/pi";
import { BiLogoFacebook } from "react-icons/bi";
import { PiPinterestLogo } from "react-icons/pi";

//
//
//
//
import { motion } from "framer-motion";
//animation Li links within the nav open
const variantsNavAnima = {
  openB: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closedB: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

const NavMenuOpen = ({ setIsMenuOpen, isMenuOpen }) => {
  //
  const location = useLocation();
  const currentPath = location.pathname;
  //
  return (
    <>
      <motion.div className="menu">
        <div className="menuOpen-container">
          <motion.ul className="navAnimated-ul">
            {/*


         */}
            {navigationItems.map(
              (itemNavi) =>
                currentPath !== itemNavi.element && (
                  <NavLink
                    key={itemNavi._id}
                    to={itemNavi.element}
                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                  >
                    <motion.li
                      className="navAnim-li"
                      variants={variantsNavAnima}
                      whileHover={{ scale: 1.1, color: "#3a3a3a" }}
                      whileTap={{ scale: 0.95 }}
                      id={itemNavi.id}
                    >
                      <span className="text-placeholder">
                        {itemNavi.titleNavi}
                      </span>
                    </motion.li>
                  </NavLink>
                )
            )}
          </motion.ul>
          {/*



         */}
          <div className="wrapper_social-bottom">
            <div className="container_social-bottom">
              {/* A */}
              <div className="box-nav-link-a">
                <div className="container-phone-email">
                  <h1>+33 977195 535</h1>
                  <h1>contact.meyoko@gmail.com</h1>
                </div>

                <div className="wrapper-socialLinks">
                  <div className="socialLinks">
                    <a
                      className="link_social-nav"
                      //https://www.instagram.com/meyokocreative
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://www.facebook.com/MelissaMurilloofficial/"
                    >
                      <BiLogoFacebook />
                    </a>
                  </div>
                  <div className="socialLinks">
                    <a
                      className="link_social-nav"
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://www.instagram.com/meyokocreative"
                    >
                      <PiInstagramLogo />
                    </a>
                  </div>

                  <div className="socialLinks">
                    <a
                      className="link_social-nav"
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://ar.pinterest.com/meyoko/"
                    >
                      <PiPinterestLogo />
                    </a>
                  </div>
                </div>
              </div>
              <div className="box-nav-link-b">
                <p className="copyright-navbar">
                  &#xa9;
                  <span>meyoko</span>
                </p>
              </div>
              <div className="box-nav-link-c">
                <div id="container-svg-navbar">
                  <svg
                    // --- star
                    className="svg-box-navbar "
                    // --- star
                    width="200"
                    height="200"
                    viewBox="0 0 200 200"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_118_208)">
                      <path
                        d="M100 200C97.1048 105.262 94.738 102.91 0 100C94.738 97.1048 97.0903 94.738 100 0C102.895 94.738 105.262 97.0903 200 100C105.262 102.91 102.91 105.233 100 200Z"
                        fill="url(#paint0_linear_118_208)"
                      />{" "}
                    </g>{" "}
                    <defs>
                      {" "}
                      <linearGradient
                        id="paint0_linear_118_208"
                        x1="14"
                        y1="26"
                        x2="179"
                        y2="179.5"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#282828" />
                        <stop offset="1" stopColor="#F9ECFF" />
                      </linearGradient>
                      <clipPath id="clip0_118_208">
                        <rect width="200" height="200" fill="#F9ECFF" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default NavMenuOpen;
// Define propTypes

const navigationItems = [
  {
    _id: 0,
    titleNavi: "about",
    element: "/about", // Corrected the path for "about"
  },
  {
    _id: 1,
    titleNavi: "gallery",
    element: "/prints-collections",
  },
  {
    _id: 2,
    titleNavi: "patterns",
    element: "/patterns-collections",
  },
  {
    _id: 3,
    titleNavi: "press",
    element: "/press",
  },
  {
    _id: 4,
    titleNavi: "contact",
    element: "/contact",
  },
];

/*
 {
    _id: 2,
    titleNavi: "Danae",
    element: "/the-danae-garden",
  },
 {
    _id: 3,
    titleNavi: "memento",
    element: "/memento-mori",
  },
*/
