import { useEffect, Fragment } from "react";
import { NavLink } from "react-router-dom";

import { motion } from "framer-motion";

import Aos from "aos";
import "aos/dist/aos.css";
//---------------
import { BtnEye } from "../../components";
import ScaleInTransition from "../../components/AnimationProps/ScaleIN-transition-enter-page";

import "./styles-fixedStickyCompImg.scss";

//-------------
//
const container = {
  hidden: { opacity: 0, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2,
    },
  },
};

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

//
//
const Index = ({ product }) => {
  //
  //
  // ------ aos
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  // ------ aos

  return (
    <>
      {" "}
      {product.imgDraw1 && (
        <>
          <div className="wrapper__drawingScreen_sticky_and_description">
            {/*  */}
            <div className="container___drawingScreen">
              <div className="full-img">
                <div className="img-box-draw-screen">
                  {product.imgDraw1 && (
                    <ScaleInTransition>
                      <div className="img2Drawing_Screen">
                        <motion.img
                          drag
                          whileDrag={{ scale: 1.4 }}
                          dragConstraints={{
                            top: -20,
                            right: 20,
                            bottom: 20,
                            left: -20,
                          }}
                          src={product.imgDraw1}
                          alt={product.altDrawingScreen}
                        />
                      </div>
                    </ScaleInTransition>
                  )}

                  {product.imgDraw2 && (
                    <ScaleInTransition>
                      <div className="img3Drawing_Screen">
                        <motion.img
                          drag
                          whileDrag={{ scale: 2.2 }}
                          dragConstraints={{
                            top: -20,
                            right: 20,
                            bottom: 20,
                            left: -20,
                          }}
                          src={product.imgDraw2}
                          alt={product.altDrawingScreen}
                        />
                      </div>
                    </ScaleInTransition>
                  )}

                  {product.imgDraw3 && (
                    <ScaleInTransition>
                      <div className="img3Drawing_Screen">
                        <motion.img
                          drag
                          whileDrag={{ scale: 2.2 }}
                          dragConstraints={{
                            top: -20,
                            right: 20,
                            bottom: 20,
                            left: -20,
                          }}
                          src={product.imgDraw3}
                          alt={product.altDrawingScreen}
                        />
                      </div>
                    </ScaleInTransition>
                  )}

                  {product.imgDraw4 && (
                    <ScaleInTransition>
                      <div className="img3Drawing_Screen">
                        <motion.img
                          drag
                          whileDrag={{ scale: 2.2 }}
                          dragConstraints={{
                            top: -20,
                            right: 20,
                            bottom: 20,
                            left: -20,
                          }}
                          src={product.imgDraw4}
                          alt={product.altDrawingScreen}
                        />
                      </div>
                    </ScaleInTransition>
                  )}
                </div>
              </div>

              <div className="wrapper_text-drawingScreen">
                <div className="text-drawingScreen-container">
                  {/* 1 */}
                  <div className="card-container-screenDraw">
                    <div className="card-deli-description-screen-draw">
                      <div className="list-title-btn-description-screen-draw">
                        <div className="list-title-wrapper">
                          <h1
                            data-aos="fade-down-left"
                            data-aos-easing="linear"
                            data-aos-duration="850"
                          >
                            {product.title}
                          </h1>
                          <h2
                            data-aos="fade-down-left"
                            data-aos-easing="linear"
                            data-aos-duration="1200"
                          >
                            {product.spanTitle}
                            <span>{product.subTitle}</span>
                          </h2>
                          {product.artCreationDate ? (
                            <h3
                              data-aos="fade-down-left"
                              data-aos-easing="linear"
                              data-aos-duration="1600"
                            >
                              {product.artCreationDate}
                            </h3>
                          ) : (
                            ""
                          )}
                          {/* 😫 */}
                          <BtnEye
                            productUrlScreenShop={product.urlToProduct}
                            urlToShop={product.urlToShop}
                          />
                        </div>
                      </div>

                      <div className="list-group-item-delivery">
                        {product.descDraw1 ? (
                          <p
                            data-aos="fade-down"
                            data-aos-easing="linear"
                            data-aos-duration="1600"
                          >
                            {product.descDraw1}...
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="card-container-screenDraw">
                    <div className="card-dimensions-screen-draw">
                      <div className="listGroup-title-dimensions">
                        <h3>Dimensions:</h3>
                      </div>

                      <div className="column-wrap-screen-draw">
                        <div className="listGroupDimensions">
                          {product.height ? (
                            <p>Height(cm) {product.height}</p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="listGroupDimensions">
                          {product.width ? (
                            <p>Width(cm) {product.width}</p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {product.materials && (
                    <div className="card-container-screenDraw">
                      <div className="card-dimensions-screen-draw">
                        <div className="listGroup-title-dimensions">
                          <h3>Materials:</h3>
                        </div>
                        <div className="column-wrap-screen-draw">
                          <div className="listGroupDimensions">
                            <p> {product.materials} </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="card-container-screenDraw__tags">
                    <div className="card-tags-screen-draw">
                      <motion.ul
                        variants={container}
                        initial="hidden"
                        animate="visible"
                        className="column-wrap-screen-draw"
                      >
                        {product.tags.map((tag) => (
                          <Fragment key={tag.id}>
                            <motion.li
                              className="listGroupTags"
                              variants={item}
                              whileHover={{
                                scale: 1.1,
                              }}
                            >
                              <NavLink
                                to={`/tags/${tag.id}/${tag.label}`}
                                className="tag-button"
                              >
                                {tag.label}
                              </NavLink>
                            </motion.li>
                          </Fragment>
                        ))}
                      </motion.ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Index;
